import { h, Component } from 'preact';
import isEqual from 'lodash/isEqual';
import { css } from 'react-emotion';
import { withContext } from '../../common/context';
import ViewerWrapper from '../ViewerWrapper/ViewerWrapper';
import { CarouselLoop, DisplayMode, ZoomType } from '../../typing/enums';
import { isTouch } from '../../utils/touch';
import { getWidth } from '../../utils/dom';
import debounce from '../../utils/debounce';
import { Events } from '../../utils/events';
import { getDimensions } from '../../utils/aspectRatio';
import AssetsNavigator from '../AssetsNavigator/AssetsNavigator';
import 'focus-visible';
import { GalleryWrapper, GallerySpacer } from './Gallery.styled';
import { KeyboardKey } from '../../typing/keyboardKey';

import { LazyComponent } from '../LazyComponent/LazyComponent';
const ZoomPopup = LazyComponent(
  () => import(/* webpackChunkName: "zoomPopup" */ '../ZoomPopup/ZoomPopup')
);

class Gallery extends Component {
  state = {
    index: this.props.context.config.selectStartIndex() || 0,
    zoomState: -1
  };

  componentDidMount() {
    const wrap = this.props.context.config.selectContainer();
    this.onResize = debounce(() => {
      if (wrap) {
        this.forceUpdate();
      } else {
        window.removeEventListener('resize', this.onResize);
      }
    }, 350);
    window.addEventListener('resize', this.onResize);
    if (this.props.context.config.selectFocus()) {
      this.setFocus();
    }
    // remove highlight when clicking on element
    if (isTouch) {
      wrap.classList.add(css`
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent; /* For some Androids */
      `);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.handleAssetsChanged();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  setItem = index => {
    const totalItems = this.props.items.length;
    this.setState({
      index: index < totalItems ? index : 0
    });
  };

  handleAssetsChanged = () => {
    const itemsCount = this.props.items && this.props.items.length;
    const currentIndex = this.state.index;
    if (currentIndex >= itemsCount) {
      this.setState({ index: itemsCount - 1 });
    }
  };

  setFocus = () => {
    const galleryWrap = this.props.context.config.selectContainer();
    const wrapperFocused =
      galleryWrap === document.activeElement || galleryWrap.contains(document.activeElement);
    if (!wrapperFocused) {
      // Focus on the first focusable element in the current gallery
      const focusable = galleryWrap.querySelector('button, [tabindex]:not([tabindex="-1"])');
      if (focusable instanceof HTMLElement) {
        focusable.focus();
      }
    }
  };

  onMouseEnter = () => {
    this.props.context.events(Events.MOUSE_ENTER, this.props.items[this.state.index].publicId);
  };

  onMouseLeave = () => {
    this.props.context.events(Events.MOUSE_LEAVE, this.props.items[this.state.index].publicId);
  };

  setZoom = zoomState => {
    if (zoomState < this.props.items.length && zoomState >= -1) {
      this.setState({ zoomState });
    }
  };

  setNextItem(index) {
    this.props.context.events(Events.VIEWER_NEXT, this.props.items[index].publicId);
    this.setItem(index);
  }

  setPrevItem(index) {
    this.props.context.events(Events.VIEWER_PREV, this.props.items[index].publicId);
    this.setItem(index);
  }

  get isJumpCarouselLoop() {
    return this.props.context.config.selectCarouselLoop() === CarouselLoop.JUMP;
  }

  nextItem = () => {
    if (this.isJumpCarouselLoop && this.state.index + 1 === this.props.items.length) {
      this.setNextItem(0);
    } else if (this.state.index < this.props.items.length - 1) {
      this.setNextItem(this.state.index + 1);
    }

    if (this.isJumpCarouselLoop && this.state.zoomState === this.props.items.length - 1) {
      this.setZoom(0);
    } else if (this.state.zoomState > -1) {
      this.setZoom(this.state.zoomState + 1);
    }
  };

  prevItem = () => {
    if (this.isJumpCarouselLoop && this.state.index === 0) {
      this.setPrevItem(this.props.items.length - 1);
    } else if (this.state.index > 0) {
      this.setPrevItem(this.state.index - 1);
    }

    if (this.isJumpCarouselLoop && this.state.zoomState === 0) {
      this.setZoom(this.props.items.length - 1);
    } else if (this.state.zoomState > 0) {
      this.setZoom(this.state.zoomState - 1);
    }
  };

  onItemSwipe = nextIndex => {
    if (nextIndex !== this.state.index) {
      this.props.context.events(
        nextIndex > this.state.index ? Events.VIEWER_NEXT : Events.VIEWER_PREV,
        this.props.items[nextIndex].publicId
      );
      this.setItem(nextIndex);
      if (this.state.zoomState > -1) {
        this.setZoom(nextIndex);
      }
    }
  };

  onKeyDown = event => {
    switch (event.key) {
      case KeyboardKey.ArrowDown:
      case KeyboardKey.ArrowRight:
        // Down/Right
        this.nextItem();
        event.preventDefault();
        break;
      case KeyboardKey.ArrowUp:
      case KeyboardKey.ArrowLeft:
        // Up/Left
        this.prevItem();
        event.preventDefault();
        break;
    }
  };

  render(props, state) {
    const { config } = props.context;
    const { width } = getDimensions(getWidth(config.selectContainer()), config.selectAspectRatio());
    const viewerDims = getDimensions(config.selectViewerWidth(), config.selectAspectRatio());
    const axis = config.selectAxis(config.selectCarouselLocation());
    const mode = config.selectDisplayPropsMode();
    const columns = config.selectDisplayPropsColumns();
    const renderNavigator = mode === DisplayMode.CLASSIC || columns === 1;
    const sticky = config.selectSticky();
    const topOffset = config.selectDisplayPropsTopOffset();
    const bottomOffset = config.selectDisplayPropsBottomOffset();
    const zoom = config.selectZoom();
    const zoomType = config.selectZoomPropsType();

    return (
      <GalleryWrapper
        mode={mode}
        carouselLocation={config.selectCarouselLocation()}
        axis={axis}
        wrapWidth={width}
        wrapHeight={viewerDims.height}
        innerRef={elem => (this.wrapper = elem)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        data-test="gallery-wrap"
        onKeyDown={this.onKeyDown}
      >
        <ViewerWrapper
          index={state.index}
          setItem={this.setItem}
          items={props.items}
          axis={axis}
          viewerDims={viewerDims}
          mode={mode}
          prevItem={this.prevItem}
          nextItem={this.nextItem}
          onItemSwipe={this.onItemSwipe}
          zoomState={state.zoomState}
          setZoom={this.setZoom}
        />

        {renderNavigator && (
          <GallerySpacer
            axis={axis}
            spacing={config.selectCarouselOffset()}
            data-test="gallery-spacer"
          />
        )}

        {renderNavigator && (
          <AssetsNavigator
            axis={axis}
            index={state.index}
            items={props.items}
            selectItem={this.setItem}
            viewerDims={viewerDims}
            width={width}
            mode={mode}
            sticky={sticky}
            topOffset={topOffset}
            bottomOffset={bottomOffset}
            setZoom={this.setZoom}
          />
        )}

        {zoom && zoomType === ZoomType.POPUP && (
          <ZoomPopup
            zoomIn={state.zoomState > -1}
            index={state.zoomState}
            type={ZoomType.POPUP}
            prevItem={this.prevItem}
            nextItem={this.nextItem}
            setZoom={this.setZoom}
            onItemSwipe={this.onItemSwipe}
            keyDownHandler={this.onKeyDown}
            wrapWidth={width}
          />
        )}
      </GalleryWrapper>
    );
  }
}

export default withContext(Gallery);
