export const DisplayMode = Object.freeze({
  CLASSIC: 'classic',
  EXPANDED: 'expanded'
});

export const Navigation = Object.freeze({
  NONE: 'none',
  ALWAYS: 'always',
  MOUSE_OVER: 'mouseover'
});

export const MediaSymbolPosition = Object.freeze({
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  CENTER: 'center'
});

export const CarouselLocation = Object.freeze({
  TOP: 'top',
  RIGHT: 'right',
  LEFT: 'left',
  BOTTOM: 'bottom'
});

export const CarouselStyle = Object.freeze({
  THUMBNAILS: 'thumbnails',
  INDICATORS: 'indicators',
  NONE: 'none'
});

export const GalleryNavigationPosition = Object.freeze({
  INSIDE: 'inside',
  MIDDLE: 'middle',
  OFFSET: 'offset'
});

export const Angle = Object.freeze({
  ANGLE_0: '0',
  ANGLE_90: '90',
  ANGLE_180: '180',
  ANGLE_270: '-90'
});

export const MediaSymbolShape = Object.freeze({
  ROUND: 'round',
  SQUARE: 'square',
  RADIUS: 'radius',
  NONE: 'none'
});

export const MediaSymbolTypes = Object.freeze({
  SPIN: 'spin',
  VIDEO: 'video',
  IMAGE: 'image',
  THREE: '3d',
  NONE: 'none'
});

export const Axis = Object.freeze({
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
});

export const Direction = Object.freeze({
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
});

export const ButtonShape = Object.freeze({
  ROUND: 'round',
  SQUARE: 'square',
  RADIUS: 'radius',
  RECTANGLE: 'rectangle',
  NONE: 'none'
});

export const ButtonSizes = Object.freeze({
  SM: 16,
  MD: 32,
  LG: 42
});

export const Transition = Object.freeze({
  SLIDE: 'slide',
  FADE: 'fade',
  NONE: 'none'
});

export const SelectedStyles = Object.freeze({
  ALL: 'all',
  BORDER: 'border',
  GRADIENT: 'gradient'
});

export const SelectedBorderPosition = Object.freeze({
  ALL: 'all',
  TOP: 'top',
  TOP_BOTTOM: 'top-bottom',
  LEFT_RIGHT: 'left-right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
});

export const IndicatorShape = Object.freeze({
  ROUND: 'round',
  SQUARE: 'square',
  RADIUS: 'radius'
});

export const ZoomViewerPosition = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
});

export const ZoomType = Object.freeze({
  INLINE: 'inline',
  FLYOUT: 'flyout',
  POPUP: 'popup'
});

export const ZoomButtonPosition = Object.freeze({
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left'
});

export const ZoomTrigger = Object.freeze({
  CLICK: 'click',
  HOVER: 'hover'
});

export const AssetType = Object.freeze({
  IMAGE: 'image',
  VIDEO: 'video'
});

export const EaseType = Object.freeze({
  LINEAR: 'Linear',
  QUADRATIC: 'Quadratic',
  CUBIC: 'Cubic',
  QUARTIC: 'Quartic',
  QUINTIC: 'Quintic',
  SINUSOIDAL: 'Sinusoidal',
  EXPONENTIAL: 'Exponential',
  CIRCULAR: 'Circular',
  ELASTIC: 'Elastic',
  BACK: 'Back',
  BOUNCE: 'Bounce'
});

export const LoaderPosition = Object.freeze({
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
  CENTER: 'center'
});

export const Skin = Object.freeze({
  ADIDAS: 'adidas',
  CA: 'ca'
});

export const Crop = Object.freeze({
  PAD: 'pad',
  FILL: 'fill',
  LIMIT: 'limit'
});

export const PreloadTypes = Object.freeze({
  IMAGE: 'image',
  VIDEO: 'video',
  SPIN: 'spin',
  THREE: '3d'
});

export const SpinAnimation = Object.freeze({
  START: 'start',
  END: 'end',
  BOTH: 'both',
  NONE: 'none'
});

export const SpinDirection = Object.freeze({
  CLOCKWISE: 'clockwise',
  COUNTERCLOCKWISE: 'counter-clockwise'
});

export const Sort = Object.freeze({
  NONE: 'none',
  ASC: 'asc',
  DESC: 'desc'
});

export const TipPosition = Object.freeze({
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom'
});

export const TipShow = Object.freeze({
  ALL: 'all',
  TOUCH: 'touch',
  DESKTOP: 'desktop',
  NEVER: 'never'
});

export const ZoomPopupShape = Object.freeze({
  ROUND: 'round',
  SQUARE: 'square',
  RADIUS: 'radius',
  NONE: 'none'
});

export const Controls = Object.freeze({
  DEPRECATED_CONTROLS_ON: 'true',
  DEPRECATED_CONTROLS_OFF: 'false',
  NONE: 'none',
  ALL: 'all',
  PLAY: 'play'
});

export const LoaderStyle = Object.freeze({
  CLOUDINARY: 'cloudinary',
  CIRCLE: 'circle',
  CUSTOM: 'custom'
});

export const MediaAltSource = Object.freeze({
  CONTEXTUAL: 'contextual',
  AUTO: 'auto',
  METADATA: 'metadata'
});

export const SortSource = Object.freeze({
  CONTEXTUAL: 'contextual',
  METADATA: 'metadata',
  PUBLIC_ID: 'public_id'
});

export const VideoPlayerType = {
  CLD: 'cloudinary',
  NATIVE: 'native'
};

export const CarouselLoop = {
  JUMP: 'jump'
};

export const FetchPriority = {
  HIGH: 'high',
  LOW: 'low',
  AUTO: 'auto'
};
