/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'preact/hooks';

/**
 * A custom hook that executes a function only once when a component mounts,
 * and optionally runs cleanup when the component unmounts.
 * This is equivalent to componentDidMount and componentWillUnmount in class components.
 *
 * @param {Function} fn - The callback function to be executed on mount.
 *                       If it returns a function, that function will be used as cleanup on unmount.
 * @example
 * useMount(() => {
 *   console.log('Component mounted');
 *   // Perform one-time setup
 *
 *   // Optional cleanup
 *   return () => {
 *     console.log('Component will unmount');
 *     // Cleanup resources
 *   };
 * });
 */
export const useMount = callback => {
  useEffect(() => {
    const cleanup = callback?.();

    return cleanup;
  }, []);
};

export default useMount;
