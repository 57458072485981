import { useEffect, useRef } from 'preact/hooks';

/**
 * A custom hook that conditionally executes an effect based on a custom comparison function.
 * Similar to useEffect, but with fine-grained control over when the effect triggers.
 *
 * @param {Function} callback - The effect callback to run. Can return a cleanup function.
 * @param {any[]} dependencies - The dependency array to watch for changes.
 * @param {Function} shouldTrigger - A comparison function that determines when the effect should run.
 *                                  Receives previous and current dependencies as arguments.
 * @returns {void}
 *
 * @example
 * useEffectWhen(
 *   () => {
 *     console.log('Effect triggered');
 *     return () => console.log('Cleanup');
 *   },
 *   [count, name],
 *   (prevDeps, currentDeps) => prevDeps[0] !== currentDeps[0] && currentDeps[0] > 5
 * );
 */
export function useEffectWhen(callback, dependencies, shouldTrigger) {
  const prevDeps = useRef(dependencies);

  useEffect(() => {
    if (shouldTrigger(prevDeps.current, dependencies)) {
      const cleanup = callback?.();
      prevDeps.current = dependencies;

      return cleanup;
    }
  }, [callback, dependencies, shouldTrigger]);
}
