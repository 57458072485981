import { h } from 'preact';
import { Transition } from '../../../typing/enums';
import { CarouselItemWrapper, ItemWrapper, SlideItemWrapper } from './CarouselItem.styled';

export const CarouselItem = props => {
  return (
    <CarouselItemWrapper data-test={`carousel-item-${props.index}`}>
      {props.transition !== Transition.SLIDE ? (
        <ItemWrapper data-test="fade-item-wrap" {...props}>
          {props.children}
        </ItemWrapper>
      ) : (
        <SlideItemWrapper data-test="slide-item-wrap" {...props}>
          {props.children}
        </SlideItemWrapper>
      )}
    </CarouselItemWrapper>
  );
};

export default CarouselItem;
