import styled from 'react-emotion';
import { Axis } from '../../typing/enums';

const getWrapperCls = props => {
  switch (props.axis) {
    case Axis.VERTICAL:
      return `
                flex-direction: column;
                width: ${props.itemWidth}px;
                height: 100%;
            `;
    case Axis.HORIZONTAL:
      return `
                width: 100%;
                height: ${props.itemHeight}px;
            `;
    default:
      return ``;
  }
};

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${props => getWrapperCls(props)};
`;

const getCarouselWrapperCls = props => {
  switch (props.axis) {
    case Axis.VERTICAL:
      return `
                width: ${props.itemWidth}px;
                height: ${
                  props.perView ? `${(props.itemHeight + props.spacing) * props.perView}px` : '100%'
                };
            `;
    case Axis.HORIZONTAL:
      return `
                height: 100%;
                width: ${
                  props.perView ? `${(props.itemWidth + props.spacing) * props.perView}px` : '100%'
                };
            `;
    default:
      return ``;
  }
};

export const CarouselWrapper = styled('div')`
  touch-action: ${props => props.touchAction && `${props.touchAction} !important`};
  opacity: ${props => (props.mounted ? 1 : 0)};
  display: block;
  position: relative;
  overflow: hidden;
  ${props => getCarouselWrapperCls(props)};
`;

const getCarouselInnerWrapperCls = props => {
  switch (props.axis) {
    case 'vertical':
      return `
        flex-direction: column;
        height: ${(props.itemHeight + props.spacing) * props.children.length - props.spacing}px;
        width: 100%;
      `;
    case 'horizontal':
      return `
          width: ${(props.itemWidth + props.spacing) * props.children.length - props.spacing}px;
      `;
    default:
      return ``;
  }
};

export const CarouselInnerWrapper = styled('div')`
  display: flex;

  img {
    display: block;
    max-width: 100%;
  }

  ${props => getCarouselInnerWrapperCls(props)};
`;
