import { h, createContext } from 'preact';
import { selectors } from './selectors';

export const Context = createContext({
  cloudinary: null,
  config: selectors,
  events: null,
  cacher: Function
});

export const withContext = Component => {
  return function ContextComponent(props) {
    return (
      <Context.Consumer>{context => <Component {...props} context={context} />}</Context.Consumer>
    );
  };
};
